import React, { createContext, useReducer } from 'react';

type Props = {
  children: React.ReactNode;
};

const initialState = {
  dataList: {},
  filterDataList: {},
  loadingData: false,
};

export const DashboardContext = createContext<{ dataList: any; loadingData: boolean; dispatch: React.Dispatch<any> }>({
  dispatch: () => null,
  dataList: {},
  loadingData: false,
});

export const DashboardReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'initialLoadData': {
      return {
        ...state,
        dataList: action.body,
        filterDataList: action.body,
        ...action.body,
      };
    }
    case 'loadData': {
      return {
        ...state,
        dataList: {
          ...state.dataList,
          ...action.body,
        },
        ...action.body,
      };
    }
    case 'setLoading': {
      return {
        ...state,
        loadingData: action.value,
      };
    }
    default:
      return state;
  }
};

const DashboardProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(DashboardReducer, initialState);

  return (
    <DashboardContext.Provider value={{ dataList: state.dataList, loadingData: state.loadingData, dispatch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
