import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import AuthService from 'src/core/services/auth.service';
import { auto_logout_delay } from 'src/config';
import SnackbarComponent from '../snackbar/Snackbar.component';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LogoutTimer = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { clearSession } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '' as AlertColor,
  });

  const inactivityTimer = useRef<NodeJS.Timeout | null>(null); // Store the timer reference

  const closeSnackbar = () => {
    setSnackbar({ open: false, severity: 'success', message: '' });
  };

  const signOut = async () => {
    try {
      await AuthService.signOut();
      setSnackbar({ open: true, severity: 'info', message: t('common.inactivityLogoutMsg') });
    } catch (error) {
      setSnackbar({ open: true, severity: 'error', message: t('common.errorOccurred') });
    } finally {
      clearSession();
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(signOut, auto_logout_delay);
  };

  useEffect(() => {
    const isLoginPage = window.location.pathname.includes('/login');

    if (isLoginPage) {
      // Clear any existing timer and return early
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
        inactivityTimer.current = null;
      }
      return;
    }

    const handleUserActivity = () => resetInactivityTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    resetInactivityTimer(); // Start the inactivity timer on mount

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      if (inactivityTimer.current) clearTimeout(inactivityTimer.current); // Cleanup on unmount
    };
  }, [window.location.pathname]); // Runs only once on mount

  return (
    <>
      <SnackbarComponent open={snackbar.open} severity={snackbar.severity} message={snackbar.message} onClose={closeSnackbar} />
      {children}
    </>
  );
};

export default LogoutTimer;
