export class Wound {
  id?: number;
  id_wound: number;
  patient_id_patient?: number;
  onset?: string;
  type?: string;
  secondary_type?: string;
  is_active: '1' | '0';
  status?: string;
  is_front?: '1' | '0';
  is_deleted?: '1' | '0';
  primary_location?: string;
  secondary_location?: string;
  position?: number;
  sub_type?: string;
  facility_acquired?: '1' | '0';
  is_worsened?: '1' | '0';
  is_pressure?: '1' | '0';
  object_values?: { secondary_location_details?: string; medical_device?: '1' | '0' };
  user?: {
    id_user: number;
    first_name: string;
    last_name: string;
  };

  constructor(wound?: any) {
    this.id_wound = +wound.id_wound;
    this.is_active = wound.is_active;
    this.id = wound.id;
    this.patient_id_patient = wound.patient_id_patient;
    this.onset = wound.onset;
    this.type = wound.type;
    this.secondary_type = wound.secondary_type;
    this.status = wound.status;
    this.is_front = wound.is_front;
    this.is_deleted = wound.is_deleted;
    this.primary_location = wound.primary_location;
    this.secondary_location = wound.secondary_location;
    this.position = wound.position;
    this.sub_type = wound.sub_type;
    this.facility_acquired = wound.facility_acquired;
    this.is_worsened = wound.is_worsened;
    this.is_pressure = wound.is_pressure;
    this.object_values = wound.object_values;
    this.user = wound.user;
  }
}

export enum DateRangeEnum {
  All = 'all',
  LastWeek = 'lastweek',
  LastMonth = 'lastmonth',
  LastYear = 'lastyear',
  Custom = 'custom',
}

export interface DateRangeType {
  startDate: Date;
  endDate: Date;
}

export class GetWoundsReq {
  clinic?: number;
  patient?: string;
}

export class LockWoundReq {
  is_active?: number;
}

export interface AddNewWoundReq {
  patient_id_patient: number | string;
  onset: string;
  primary_location: number;
  secondary_location: number;
  type: string;
  secondary_type?: string;
  sub_type: string;
  is_front: number;
  user_id_user: number;
  facility_acquired: string;
  object_values?: Record<string, unknown>;
}

export interface GetWoundTypesReq {
  clinic: number;
  account: number;
  app: string;
  page: string;
  type: string;
}

export interface UpdateWoundReq {
  id: number;
  patient_id_patient?: number | string;
  patient_id?: number | string;
  onset: string;
  primary_location: number;
  secondary_location: number;
  type: string;
  secondary_type?: string;
  sub_type: string;
  is_front: number;
  user_id_user: number;
  facility_acquired: string;
  object_values?: any;
}

export enum WoundStatus {
  Active = 'active',
  Healed = 'healed',
  Closed = 'closed',
}

export interface pdfReportSetting {
  isCustomLogo: string;
  dateRange: DateRangeType | null;
  dateRangeSelection: DateRangeEnum;
  measurementDetails: boolean;
  signature: boolean;
  appendix: boolean;
  deidentify: boolean;
}

export const Stages: { [key: string]: string } = {
  'Stage 1': '1',
  'Stage 2': '2',
  'Stage 3': '3',
  'Stage 4': '4',
  'Stage I': '1',
  'Stage II': '2',
  'Stage III': '3',
  'Stage IV': '4',
  Unstageable: '5',
  'Deep Tissue Injury': '6',
  'Deep Tissue Pressure Injury': '6',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': 'Unstageable',
  '6': 'Deep Tissue Pressure Injury',
};

export enum WoundStageEnum {
  Stage1 = 'Stage 1',
  Stage2 = 'Stage 2',
  Stage3 = 'Stage 3',
  Stage4 = 'Stage 4',
  StageI = 'Stage I',
  StageII = 'Stage II',
  StageIII = 'Stage III',
  StageIV = 'Stage IV',
  Unstageable = 'Unstageable',
  DeepTissueInjury = 'Deep Tissue Injury',
}

export enum WoundType {
  PressureInjury = 'Pressure Injury',
  MASD = 'MASD (Moisture-Associated Skin Damage)',
}

export enum WoundSubType {
  IAD = 'IAD (Incontinence-Associated Dermatitis)',
  MucosalMembrane = 'Mucosal membrane',
}

export interface WoundLocationModel {
  primary: string;
  suffix: string;
  secondary: string[];
}

export class WoundLocationsListModel {
  [key: string]: WoundLocationModel;
  constructor(data: any) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        this[key] = data[key];
      }
    }
  }
}
