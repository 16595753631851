import { useState, createContext, useContext, useEffect } from 'react';
import AppContext from 'src/core/context/global/AppContext';

// Define the type for the chart filter data
interface ChartFilterData {
  woundPresence: { presence: string[] };
  woundType: { type: string[] };
  woundLocation: { primary_location_name: string[] };
  patientGender: { gender: string[] };
  patientAgeRange: { patientAgeRange: string[] };
  woundHealingStatus: { status: string[] };
  account: { account: string | number };
  site: { site_id: (string | number)[] };
  patientStatus: { patient_status: (string | number)[] };
  woundStatus: { is_active: (string | number)[] };
}

// Define the context interface
interface ChartFilterContextType {
  chartFilter: ChartFilterData;
  setChartFilter: React.Dispatch<React.SetStateAction<ChartFilterData>>;
  initialChartFilter: ChartFilterData;
  setInitialChartFilter: React.Dispatch<React.SetStateAction<ChartFilterData>>;
}

// Create the context with a default empty object
export const ChartFilterContext = createContext<ChartFilterContextType | object>({});

export const ChartFilterProvider = ({ children }: any) => {
  const { user, structure } = useContext(AppContext);

  // Define empty filter data structure with the account ID from the structure
  const emptyData: ChartFilterData = {
    woundPresence: { presence: [] },
    woundType: { type: [] },
    woundLocation: { primary_location_name: [] },
    patientGender: { gender: [] },
    patientAgeRange: { patientAgeRange: [] },
    woundHealingStatus: { status: [] },
    account: { account: structure?.id || '' },
    site: { site_id: [] },
    patientStatus: { patient_status: [] },
    woundStatus: { is_active: [] },
  };

  // State for initial and current filter values
  const [initialChartFilter, setInitialChartFilter] = useState<ChartFilterData>(emptyData);
  const [chartFilter, setChartFilter] = useState<ChartFilterData>(emptyData);

  // Log filter state changes in development
  useEffect(() => {
    console.log('Chart filter updated:', {
      sites: chartFilter.site.site_id.length,
      statuses: chartFilter.patientStatus.patient_status.length,
      woundTypes: chartFilter.woundType.type.length,
    });
  }, [chartFilter]);

  // Update account ID if structure changes
  useEffect(() => {
    if (structure?.id && structure.id !== chartFilter.account.account) {
      setChartFilter(prev => ({
        ...prev,
        account: { account: structure.id },
      }));

      setInitialChartFilter(prev => ({
        ...prev,
        account: { account: structure.id },
      }));
    }
  }, [structure]);

  // Context value
  const values: ChartFilterContextType = {
    chartFilter,
    setChartFilter,
    initialChartFilter,
    setInitialChartFilter,
  };

  return <ChartFilterContext.Provider value={values}>{children}</ChartFilterContext.Provider>;
};
